import { useEffect } from 'react';

function RedirectPage() {
    useEffect(() => {
        window.location.replace("https://www.clozers.co");
    }, []);

    return null; // No need to render anything
};

export default RedirectPage;